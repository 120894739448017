@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* font-family: 'Playfair Display', serif; */
html {
  font-size: 15px;
  scroll-behavior: smooth;
  font-weight: 500;
}
body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  font-weight: 500;
  background: #16161b;
  color: #ffffff;
}

body::-webkit-scrollbar {
  width: 10px;
  display: block;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #fff2;
  border: 1px solid #fff7;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #fff5;
  border: 1px solid #fffa;
  border-radius: 100px;
}

.overflow-y-visible::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  display: block;
}

.overflow-y-visible::-webkit-scrollbar-track {
  background-color: transparent;
}

.overflow-y-visible::-webkit-scrollbar-thumb {
  background-color: #fff2;
  border: 1px solid #fff7;
  border-radius: 100px;
}

.overflow-y-visible::-webkit-scrollbar-thumb:hover {
  background-color: #fff5;
  border: 1px solid #fffa;
  border-radius: 100px;
  width: 7px;
  height: 7px;
}

body {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: #fff2;
  border-radius: 100px;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #fff5;
}

@layer base {
  input,
  select,
  textarea,
  .input {
    @apply outline-none bg-transparent border-2 border-componentBorder focus:border-componentFocus duration-100 rounded-lg p-2.5;
  }

  select {
    @apply bg-[#13373f];
  }

  [type="radio"]:checked,
  [type="checkbox"]:checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+DQo8ZGVmcz4NCiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImdyYWRpZW50IiB4MT0iMCUiIHkxPSIwJSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQogICAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDBGQkZCIi8+DQogICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMEE2NzIiLz4NCiAgICA8L2xpbmVhckdyYWRpZW50Pg0KICAgIDwvZGVmcz4NCjxyZWN0IGZpbGw9InVybCgjZ3JhZGllbnQpIiBoZWlnaHQ9IjE2IiB3aWR0aD0iMTYiIHJ4PSIyIi8+PC9zdmc+);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    /* border-color: transparent; */
  }

  [type="checkbox"],
  [type="radio"] {
    @apply focus:border-white cursor-pointer;
    print-color-adjust: exact;
    --tw-shadow: 0 0 #0000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: 50%;
    background-size: 0% 0%;
    /* background-color: #fff; */
    background-origin: border-box;
    border-color: white;
    border-radius: 3px;
    border-width: 1px;
    color: #1c64f2;
    display: inline-block;
    flex-shrink: 0;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }

  .nav-button,
  button,
  .button {
    @apply rounded-lg py-2 px-4 font-medium hover:bg-opacity-80;
  }

  .bitbot-button {
    background-color: #7e84ff;
    @apply hover:bg-[#646cff] duration-100;
  }

  button:disabled {
    @apply grayscale cursor-not-allowed;
  }

  .home-logo {
    @apply w-[50px] h-[50px] dark-logo bg-no-repeat bg-contain bg-center;
    filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px 1px 0 white)
      drop-shadow(1px -1px 0 white) drop-shadow(-1px -1px 0 white);
  }

  .dark-logo {
    background-image: url("./assets/images/bitbot-finance.png");
  }

  .main-container {
    @apply w-full 2xl:px-64 xl:px-48 lg:px-32 3xl:px-80 4xl:px-[40rem] px-6 overflow-hidden relative mb-8;
    min-height: calc(100vh - 70px);
  }

  .bg-bitbotGr {
    background: linear-gradient(90deg, #ff85e7 0%, #d22e00 100%);
  }

  .text-bitbot-gr {
    background: linear-gradient(270deg, #f5da67, #fd7621);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .smooth-input {
    @apply border bg-bitbotItemDarkBg pl-6 py-3;
  }

  .toast-wrapper {
    @apply text-white max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 border border-white/50 cursor-pointer backdrop-blur-sm;
  }
  .toast-wrapper.toast-success {
    @apply bg-gradient-to-r from-green-500/50 dark:from-green-500/10 to-white/5;
  }
  .toast-wrapper.toast-success .toast-title {
    @apply text-lime-700 dark:text-lime-500 tracking-wider;
  }
  .toast-wrapper.toast-error {
    @apply bg-gradient-to-r from-red-500/50 dark:from-red-500/10 to-white/5;
  }
  .toast-wrapper.toast-error .toast-title {
    @apply text-rose-800 dark:text-rose-600 tracking-wider;
  }
  .toast-wrapper.toast-info {
    @apply bg-gradient-to-r from-purple-900/10 to-white/5;
  }
  .toast-wrapper.toast-info .toast-title {
    @apply text-fuchsia-800 dark:text-fuchsia-400 tracking-wider;
  }

  .featured-product::after {
    content: "";
    position: absolute;
    /* top: -2px; */
    width: 100px;
    height: 80px;
    background-image: url("./assets/images/hotsale-badge.png");
    background-size: contain;
  }

  @keyframes bounce-in {
    0% {
      animation-timing-function: cubic-bezier(0.0383, 0.3388, 0.0421, 0.6652);
      transform: matrix(0.01, 0, 0, 0.01, 0, 0);
      opacity: 1;
    }

    4.7% {
      animation-timing-function: cubic-bezier(0.3296, 0.3336, 0.5772, 0.6672);
      transform: matrix(0.556, 0, 0, 0.556, 0, 0);
      opacity: 1;
    }

    8.4% {
      animation-timing-function: cubic-bezier(0.0781, 0.1617, 0.0874, 0.9301);
      transform: matrix(0.979, 0, 0, 0.979, 0, 0);
      opacity: 1;
    }

    12.4% {
      animation-timing-function: cubic-bezier(0.8341, 0.1496, 0.8634, 0.7673);
      transform: matrix(1.153, 0, 0, 1.153, 0, 0);
      opacity: 1;
    }

    16.3% {
      animation-timing-function: cubic-bezier(0.3264, 0.3332, 0.5758, 0.6695);
      transform: matrix(1.008, 0, 0, 1.008, 0, 0);
      opacity: 1;
    }
    21.2% {
      animation-timing-function: cubic-bezier(0.0921, 0.1883, 0.3277, 1);
      transform: matrix(0.704, 0, 0, 0.704, 0, 0);
      opacity: 1;
    }
    24.5% {
      animation-timing-function: cubic-bezier(0.6905, 0.0944, 0.8759, 0.7624);
      transform: matrix(0.626, 0, 0, 0.626, 0, 0);
      opacity: 1;
    }
    27.7% {
      animation-timing-function: cubic-bezier(0.3688, 0.332, 0.624, 0.6684);
      transform: matrix(0.704, 0, 0, 0.704, 0, 0);
      opacity: 1;
    }
    32.6% {
      animation-timing-function: cubic-bezier(0.1368, 0.2364, 0.2451, 0.9049);
      transform: matrix(0.958, 0, 0, 0.958, 0, 0);
      opacity: 1;
    }
    37.4% {
      animation-timing-function: cubic-bezier(0.5936, 0.0846, 0.3495, 1);
      transform: matrix(1.085, 0, 0, 1.085, 0, 0);
      opacity: 1;
    }
    49.5% {
      animation-timing-function: cubic-bezier(0.5522, 0.0981, 0.3807, 1);
      transform: matrix(0.802, 0, 0, 0.802, 0, 0);
      opacity: 1;
    }
    62.4% {
      animation-timing-function: cubic-bezier(0.4497, 0.1349, 0.4911, 1);
      transform: matrix(1.044, 0, 0, 1.044, 0, 0);
      opacity: 1;
    }
    74.1% {
      animation-timing-function: cubic-bezier(0.429, 0, 0.5239, 1);
      transform: matrix(0.914, 0, 0, 0.914, 0, 0);
      opacity: 1;
    }

    87% {
      animation-timing-function: cubic-bezier(0.3501, 0, 0.6422, 1);
      transform: matrix(1.013, 0, 0, 1.013, 0, 0);
      opacity: 1;
    }

    95.8% {
      animation-timing-function: cubic-bezier(0.3653, 0, 0.6776, 1);
      transform: matrix(0.992, 0, 0, 0.992, 0, 0);
      opacity: 1;
    }

    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
